import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
    config: {
        //connectionString: "InstrumentationKey=dce0bcb7-0d68-4c6a-83e1-3afaf3efe52b;IngestionEndpoint=https://japaneast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://japaneast.livediagnostics.monitor.azure.com/;ApplicationId=cf774937-e1a6-480f-b2e1-2b5b621c801c", // Application Insights の接続文字列
        connectionString: process.env.VUE_APP_APPLICATIONINSIGHTS, // Application Insights の接続文字列
        enableAutoRouteTracking: true, // ページビューの自動追跡
    },
});

appInsights.loadAppInsights();

export default appInsights;
